































import SfSelectOption from '@storefront-ui/vue/src/components/molecules/SfSelect/_internal/SfSelectOption.vue';
import { defineComponent } from '@nuxtjs/composition-api';
import type { ComputedRef } from '@nuxtjs/composition-api';
import { computed } from '@vue/composition-api';
import Select from '~/components/atoms/Inputs/Select/Select.vue';
import { useI18n, useLanguageSelector } from '~/composables';

export default defineComponent({
  name: 'LanguageSelector',
  components: { Select, SfSelectOption },
  setup() {
    const { countryLocalization, languageAndCountry: activelanguageAndCountry } = useI18n();
    const { changeLanguage } = useLanguageSelector();

    const flagPath: ComputedRef<string> = computed(() =>
      require(`@/assets/images/flags/${countryLocalization.value.flagFile}`));

    return {
      countryLocalization,
      activelanguageAndCountry,
      changeLanguage,
      flagPath
    };
  }
});

